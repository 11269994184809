<template>
  <div>
    <v-navigation-drawer
      clipped
      v-model="isDrawerOpen"
      absolute
      temporary
      width="80%"
    >
      <v-row align="center" justify="start">
        <div class="mt-8 ml-8">
          <v-img
            contain
            :src="require('@/assets/Logo.svg')"
            transition="scale-transition"
            width="59"
            class="d-flex align-end"
          />
        </div>
        <div class="mt-8 ml-8">
          <v-img
            contain
            :src="require('@/assets/footer-logo.svg')"
            transition="scale-transition"
            width="100"
            class="d-flex align-end"
          />
        </div>
      </v-row>

      <div class="my-12 items-container">
        <div
          v-if="getCurrentUser && getCurrentUser.displayName"
          class="green--text font-weight-bold mb-12"
        >
          Hi, {{ getCurrentUser.displayName }} !
        </div>
        <v-btn
          width="100%"
          height="60px"
          class="item-text"
          text
          @click="
            $router.push({ name: 'CustomerDashboard' }, () => {});
            isDrawerOpen = false;
          "
        >
          Home
        </v-btn>
        <a href="mailto: support@thetoucan.app"  style="width: 100%">
          <v-btn width="100%" height="60px" class="item-text" text>
            Get in touch
          </v-btn>
        </a>
        <a href="https://thetoucan.app/faqs/" target=”_blank” style="width: 100%">
          <v-btn width="100%" height="60px" class="item-text" text>
            FAQs
          </v-btn>
        </a>
        <v-btn
          v-if="!getCurrentUser"
          width="100%"
          height="60px"
          class="item-text"
          text
          @click="
            $router.push({ name: 'SignIn' }, () => {});
            isDrawerOpen = false;
          "
        >
          Login
        </v-btn>
        <v-btn
          v-if="getCurrentUser"
          width="100%"
          height="60px"
          class="item-text"
          text
          @click="logout()"
        >
          Logout
        </v-btn>
      </div>

      <v-row class="col-12" no-gutters justify="center">
        <span> © Toucan 2022 </span>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DrawerMixin from "@/mixins/DrawerMixin.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [DrawerMixin],
  data() {
    return {
      items: [
        {
          title: "Home",
          route: "CustomerDashboard",
        },
        {
          title: "Get in touch",
          route: "CustomerDashboard",
        },
        {
          title: "About Zero",
          route: "CustomerDashboard",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style  >
.v-navigation-drawer {
  border-radius: 0px 60px 60px 0px;
}
.items-container {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16rem;
  line-height: 18px;
}
</style>