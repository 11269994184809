<template>
  <v-app>
    <div style="height: 100vh">
      <Drawer />
      <v-app-bar
        hide-on-scroll
        app
        color="rgba(250, 250, 250, 0.64)"
        height="102"
        elevation="0"
      >
        <v-toolbar-title>
          <div>
            <v-img
              alt="Vuetify Logo"
              contain
              :src="require('@/assets/Logo.svg')"
              transition="scale-transition"
              width="30"
              class="d-flex align-end"
            /></div
        ></v-toolbar-title>

        <v-spacer></v-spacer>
        <div class="app-bar-title">{{ getCurrentAppBarTitle }}</div>

        <v-spacer></v-spacer>
        <v-app-bar-nav-icon
          class="black--text"
          @click="isDrawerOpen = !isDrawerOpen"
        ></v-app-bar-nav-icon>
      </v-app-bar>

      <v-main class="mx-5 my-2">
        <router-view />
      </v-main>
      <v-spacer></v-spacer>
      <v-row class="col-12" no-gutters justify="center">
        <div>
          <v-img
            alt="Vuetify Logo"
            contain
            :src="require('@/assets/footer-logo.svg')"
            transition="scale-transition"
            width="100"
            class="d-flex align-end"
          />
        </div>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import DrawerMixin from "@/mixins/DrawerMixin.vue";
import Drawer from "@/components/global/Drawer.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Drawer,
  },
  mixins: [DrawerMixin],
  data: () => ({
    isEmailResent: false,
  }),
  computed: {
    ...mapGetters(["getCurrentUser", "getCurrentAppBarTitle"]),
  },
};
</script>

<style lang="scss" scoped>
</style>